import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { getPlatform } from '@/utils/util';

import { Toast, Dialog, Button, Rate } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/lib/index.css';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';

if (getPlatform().isMiniprogram) {
  (window as any).wx = require('weixin-js-sdk-ts');
}

function render() {
  const app = createApp(App);
  app.use(store);
  app.use(router);
  app.use(Toast);
  app.use(Dialog);
  app.use(Button);
  app.use(Rate);
  app.mount('#app');
}
render()

// 从云课堂进入，使用iframe嵌入，使用单独的token和布局
// if (window.name === 'gupaoEduClue') {
//   // 子页面接收信息
//   window.addEventListener('message', async (e) => {
//     if(typeof(e.data)=='string'){
//       const props = JSON.parse(e.data)
//       if (props.event === 'edu_updateToken'){
//         const { parames } = props
//         try{
//           // 更新用户信息
//           await updateTokenAndInfo(parames.token)
//         }catch(e){
//           console.error('题库报错:',e)
//         }finally{
//           window.location.reload()
//         }
//       }
//     }
//   })
// }


