import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';

export default class FeedModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {};
  }

  getters: GetterTree<State, Root> = {}

  actions: ActionTree<State, Root> = {
    // 提交题目反馈
    async feedQuestion({}, params) {
      const r: ReturnPage = await axios.post(`/api/quiz/feedback`, params);
      return r;
    },
  }

  mutations: MutationTree<State> = {}
}
