import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';
import { SET_LIST } from './types';
import { logoTextConvert } from '@/utils/util';

export default class IndexModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {
      list: []
    };
  }

  getters: GetterTree<State, Root> = {
    list: state => state.list
  }

  actions: ActionTree<State, Root> = {
    // 查询指定类型的菜单项
    async getMenuByTypeId({}, params) {
      const r: ReturnPage = await axios.post(`/admin/quiz/dict/page`, params);
      return r;
    },
    // 查询学科
    async getDiscipline({}) {
      const r: ReturnPage = await axios.get(`/curriculum/classification`);
      return r;
    },
    // 首页搜索题库列表（专项练习）
    async getQuestionPageList({ commit, state }, params) {
      const r: ReturnPage = await axios.post(`/web/quiz/package/page`, params);
      if (r.code === 0) {
        const { pageIndex } = params;
        const curr = r.data.data.map((n: any) => {
          // 左边图片上的文本显示处理
          n.subName = logoTextConvert(n.subName);
          n.quizPositionName = (n.quizPositionName || '').split(',').join(' | ');
          return n;
        });
        const list = pageIndex === 1 ? curr : state.list.concat(curr);
        commit(SET_LIST, list);
      }
      return r;
    },
    // 清空list
    clear({ commit }) {
      commit(SET_LIST, []);
    }
  }

  mutations: MutationTree<State> = {
    [SET_LIST](state, data) {
      state.list = data;
    }
  }
}
