// 字母索引
export const Letters = ['A', 'B', 'C', 'D' , 'E', 'F', 'G' , 'H' , 'I', 'J', 'K' , 'L' , 'M'];
// 登陆token存储的key
export const LOGINTOKEN = 'gp_q_tk';
export const USERID = 'gp_q_userid';
export const USERUUID = 'gp_q_useruuid';
export const USERNAME = 'gp_q_username';
// 考卷难度
export const DIFFICULTY = [{
  text: '容易',
  value: 1
}, {
  text: '偏易',
  value: 2
}, {
  text: '适中',
  value: 3
}, {
  text: '偏难',
  value: 4
}, {
  text: '困难',
  value: 5
}];
// oss 的海报相关图片地址
export const OSSPOSTER = 'https://gp-www-cdn.oss-cn-shenzhen.aliyuncs.com/question/poster';
