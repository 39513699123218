import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';
import { SET_DATA } from './types';
import { convertFormatTime } from '@/utils/util';

export default class ResultModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {
      data: {
        qaCount: 0,
        rightQaCount: 0,
        rightQaPercent: 0,
        duration: 0
      }
    };
  }

  getters: GetterTree<State, Root> = {
    data: state => state.data
  }

  actions: ActionTree<State, Root> = {
    // 获取当前试卷解析结果
    async getQuestionAnalyze({ commit }, id) {
      const r: ReturnPage = await axios.get(`/web/quiz/package/info/${id}/result`);
      if (r.code === 0) {
        const data = r.data;
        data.qaCount = r.data.questionList?.length || 1;
        data.rightQaPercent = Math.trunc((data.rightQaCount / data.qaCount) * 100);
        // data.duration = convertFormatTime(data.duration);
        commit(SET_DATA, data);
      }
      return r;
    },
  }

  mutations: MutationTree<State> = {
    [SET_DATA](state, data) {
      state.data = data;
    }
  }
}
