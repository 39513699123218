import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import onWholeWaterMark from '@/utils/watermark'
import cookies from 'js-cookie';
import { USERUUID, USERNAME } from '@/constants/common';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/paper/:id',
    component: ()  => import('@/views/paper.vue')
  },
  {
    path: '/question/:id/:type?',
    component: () => import('@/views/question.vue'),
  },
  {
    path: '/result/:id',
    component: () => import('@/views/result.vue'),
  },
  {
    path: '/result/:id/acti',
    component: () => import('@/views/resultActivity.vue'),
  },
  {
    path: '/analyze/:id/:type',
    component: () => import('@/views/analyze.vue'),
  },
  {
    path: '/feed/:id',
    component: () => import('@/views/feed.vue')
  },
  {
    path: '/auth',
    component: () => import('@/views/auth.vue')
  },
  {
    path: '/*',
    component: () => import('@/views/notfound.vue'),
  },
  // 测试用。后面删除掉吧
  {
    path: '/career',
    component: () => import('@/views/careerHome.vue'),
  },
  {
    path: '/career/teacher',
    component: () => import('@/views/careerTeacher.vue'),
  },

  // --- 问卷调查
  {
    path: '/survey/test/:id',
    component: () => import('@/views/survey/test.vue'),
  },
  // --- 问卷调查
  {
    path: '/survey/finished',
    component: () => import('@/views/survey/finished.vue'),
  }
]

const basePath = process.env.BASE_URL
console.log('部署路径：',basePath)

const router = createRouter({
  history: createWebHistory(basePath),
  routes
})


router.beforeEach((to,from,next)=>{
  const name = cookies.get(USERNAME) || cookies.get(USERUUID);
  // 打上水印
  if(name)onWholeWaterMark('咕泡科技-'+ name)
  next();
})

export default router
