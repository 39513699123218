import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';
import { SET_DATA, SET_LENS, SET_DURATION } from './types';
import router from '@/router';
import _ from 'lodash';
import { momentClient } from '@/utils/axios'

export default class QuestionModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {
      data: {
        countdown: 60 * 60 * 1000,  // 默认倒计时
        quizInfoList: [],
      },
      lens: 0
    };
  }

  getters: GetterTree<State, Root> = {
    data: state => state.data,
    lens: state => state.lens
  }

  actions: ActionTree<State, Root> = {
    // 查询问题列表
    async getQuestionList({ commit, state }, id) {
      const r: ReturnPage = await axios.get(`/web/quiz/package/info/${id}/quizList`);
      if (r.code === 0) {
        const data = r.data;
        // 将分钟转化为毫秒
        data.countdown = data.duration * 60 * 1000;
        // data.countdown = state.data.countdown? state.data.countdown : data.duration * 60 * 1000;
        // 活动来跳来的每次都打乱题目顺序
        if (router.currentRoute.value.query.acti) {
          data.quizInfoList = _.shuffle(data.quizInfoList);
        }
        commit(SET_DATA, data);
        commit(SET_LENS, data.quizInfoList.length);
      }
      return r;
    },

    // 查询错题集列表
    async getErrorRecordList({ commit }, id) {
      const {data} = await momentClient.get(`/moment/api/ke/upackage/error/page/package/${id}`);
      if(data.code == 0){
        data.data = data.data.map((item: any)=> ({...item, optionList: item.options, realQuestion: item.answer}))
        data.quizInfoList = data.data
        commit(SET_DATA, data);
        commit(SET_LENS, data.quizInfoList.length);
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    },

    // 提交作答结果进行验证
    async submitAnswerValid({}, parmas) {
      const r: ReturnPage = await axios.post(`/web/quiz/package/commit`, parmas);
      return r;
    },

    // 查询活动详情
    async getActivityInfoById({}, id: number) {
      return await axios.get(`${process.env.VUE_APP_EDU_BASE_URL}moment/api/ke/jrActivity/${id}`);
    },

    // 查找进行中的答题记录
    async checkExaming ({commit}, id){
      const params = {id}
      const {data} = await momentClient.get('/moment/api/ke/upackage/findPackageIngList',{params});
      // 计算剩余时间
      if(data.code == 0){
        const createTime = new Date(data.data?.examine?.createTime || 0).getTime();
        const currentTime = new Date().getTime();
        const defft = (currentTime - createTime)/1000; // 时间差（秒）
        const duration = (data.data?.examine?.duration || 0) * 60 // 考试时长(秒);
        const residue = (duration - defft); // 剩余时间(秒) 
        console.log('剩余时间:',defft,duration,residue/60 * 1000);
        // commit(SET_DURATION, residue < 0?  10000 : residue);

        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    },

    // 开始答题
    async starAnswer(_, packageId){
      const params = {packageId}
      const {data} = await momentClient.post('/moment/api/ke/upackage/startAnswer',params);
      if(data.code == 0){
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    },

    // 结束答题
    async endAnswer(_, id){
      const params = {id}
      const {data} = await momentClient.put('/moment/api/ke/upackage/endAnswer',params);
      if(data.code == 0){
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    },

    // 重新开始
    async againAnswer(_, params){
      const {data} = await momentClient.post('/moment/api/ke/upackage/restartAnswer',params);
      if(data.code == 0){
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    },

    // 提交一个回答
    async commitOnceAnswer (_, params){
      const {data} = await momentClient.post('/moment/api/ke/upackage/commitAAnswer',params);
      if(data.code == 0){
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    },

    // 删除错题集中的题目
    async deleteOnceQuestion(_, quizId){
      const {data} = await momentClient.post('/moment/api/ke/upackage/deleteErrorAnswer',{quizId});
      if(data.code == 0){
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    }
  }

  mutations: MutationTree<State> = {
    [SET_DATA](state, data) {
      state.data = data;
    },
    [SET_LENS](state, data) {
      state.lens = data;
    },
    [SET_DURATION](state, countdown){
      state.data.countdown = countdown; 
    }
  }
}
