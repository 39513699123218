import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';
import { SET_USER } from './types';

const BASE_URL = process.env.VUE_APP_EDU_BASE_URL;
const WX_APPID = process.env.VUE_APP_WX_APPID;


export default class UserModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {
      user: {}
    };
  }

  getters: GetterTree<State, Root> = {
    user: state => state.user
  }

  actions: ActionTree<State, Root> = {
    // 发送验证码
    async sendsmsCode({}, params) {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]: any[]) => {
        formData.append(key, value)
      });
      const r: ReturnPage = await axios.post(`/account/sendValidCode`, formData);
      return r;
    },
    // 通过验证码登陆
    async loginBySmsCode({}, params) {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]: any[]) => {
        formData.append(key, value)
      });
      const r: ReturnPage = await axios.post(`/account/login`, formData);
      return r;
    },
    // 获取用户的登陆信息
    async getUserInformation({ commit }) {
      const r: ReturnPage = await axios.get(`/user/detailInfo`);
      if (r.code === 0) {
        commit(SET_USER, r.data);
      }
      return r;
    },
    // jssdk授权接口
    async getJssdkAnthorizeInfo() {
      const r: ReturnPage = await axios.get(`${BASE_URL}wx-api/wx/jsapi/${WX_APPID}/getWxJsapiInfo`);
      return r;
    }
  }

  mutations: MutationTree<State> = {
    [SET_USER](state, data) {
      state.user = data;
    }
  }
}
