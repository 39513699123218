import type { Platform } from '@/typings/utils/util';
import { LOGINTOKEN, USERUUID, USERID, USERNAME } from '@/constants/common';
import cookies from 'js-cookie';
import Store from '@/store';
import router from '@/router/index'

/**
 * 获取当前平台的类型
 * @returns 
 */
export function getPlatform(): Platform {
  const u = window.navigator.userAgent;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端23
  const isMiniprogram = /MicroMessenger/.test(u);
	const isWxDevTool = isMiniprogram && /wechatdevtools/.test(u);
  const isApp = !!(window as any).webkit?.messageHandlers?.jumpAppPage || !!(window as any).appObject;
	const isPC = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null? false : true;
  const isPhoneX = isIOS && window.screen.height >= (isWxDevTool ? 724 : 812);

  return {
    isApp,
    isIOS,
    isAndroid,
    isMiniprogram,
		isPC,
		isPhoneX
  };
}

/**
 * 调用客户端的功能
 * @param {string} name 协议名称
 * @param {object} args 协议参数
 */
export function dispatchAppFeature(name: string, args: unknown) : void{
	const { isIOS, isApp } = getPlatform();
	if (isApp) {
		if (isIOS) {
			// 调用 ios 客户端
			(<any>window).webkit.messageHandlers[name].postMessage(args);
		} else {
			try{
				// 调用 androind 客户端
				// android的实现问题，如果没有定义参数，则必须什么都不传
				args ? (<any>window).appObject[name](JSON.stringify(args)) : (<any>window).appObject[name]();
			}catch(e){
				console.log('安卓报错了：',e)
			}
		}
	} else {
		console.log(`%c模拟触发调用客户端协议 [${name}]`, 'color: green', args);
	}
}


// 获取客户端传递的token
export function getAppToken(): string {
  // 获取当前平台
  const { isIOS, isApp } = getPlatform();
  // 只有在app的情况下才处理
  if (isApp) {
    return isIOS ? window.prompt("getNativeToken") as string : (window as any).appObject.getToken();
  }
  return '';
}

// 圆圈中的文本处理
export function logoTextConvert(text: string): string {
  if (/^[\u4e00-\u9fa5]+$/.test(text)) {
    return text.length > 3 ? `${text.slice(0, 2)}<br>${text.slice(2)}` : text;
  }
  return text;
}

/**
 * 将指定毫秒转成时分秒
 * @param times 当前毫秒数
 * @returns 
 */
export function convertFormatTime(times = 0): string {
	if(typeof times === 'number'){
		if(times <= 0){
			return '00:00:00';
		}else{
			const hh  = Math.floor(times / 3600000);
			const shh = Math.floor(times - hh * 3600000);
			const mm  = Math.floor(shh / 60000);
			const ss  = Math.floor((shh - mm * 60000) / 1000);
			return (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0'+ mm : mm) +':'+(ss < 10 ? '0'+ ss : ss);
		}
	}else{
		return '00:00:00';
	}
}

/**
 * 截取混合字节指定长度
 * @param str 字符串
 * @param len 指定长度
 * @returns 
 */
export function substrMixinStr(str: string, len: number): string {
	if(!str || !len) { return ''; }

	// 中文2字节，英文1字节
	let a = 0;
	//循环计数
	let i = 0;

	//临时字串
	let temp = '';

	for (i = 0; i < str.length; i++) {
		if (str.charCodeAt(i) > 255) {
			a += 2;
		} else {
			a++;
		}
		//如果增加计数后长度大于限定长度，就直接返回临时字符串
		if(a > len) { return temp; }

		//将当前内容加到临时字符串
		temp += str.charAt(i);
	}
	//如果全部是单字节字符，就直接返回源字符串
	return str;
}

/**
 * 获取指定字符串单字节总长度
 * @param txt 字符串
 * @returns 
 */
export function getMixinStrLens(txt: string): number {
  // eslint-disable-next-line no-control-regex
  return (txt.match(/[^\x00-\xff]+/g) || []).reduce((x, y) => x + y.length, 0) + txt.length;
}

/**
 * 跳转咕泡授权
 * @param {String} callbackPath 授权成功后进入的路由
 */ 
export function gupaoEduAuth(callbackPath : string): void{
		// 加一个随机数
	const t = new Date().getTime()
	// 重定向页面
	const basePath = router.options?.history?.base || ''
	let redirect_uri = location.protocol+ '//' + location.host + basePath +'/auth?full_path=' + callbackPath + `&dd_temp=${t}`;
	// 编码
	redirect_uri = window.btoa(encodeURIComponent(redirect_uri));
	// // 咕泡授权URL (unstrong: 不需要强制绑定手机号)
	window.location.href = `${process.env.VUE_APP_EDU_BASE_URL}auth?redirect_uri=${redirect_uri}&appid=20220915&dev=1&unstrong=1`;
}

/**
 * 更新token 与用户信息
*/
export async function updateTokenAndInfo(token: string){
	// 1、保存到Cookies
	cookies.set(LOGINTOKEN, token);
	// 2、只有在app才处理
	if (getPlatform().isApp) {
		dispatchAppFeature('updatetoken', {
			token: token
		});
	}
	// 3、获取用户信息
	const r2 = await Store.dispatch('User/getUserInformation');
	if (r2.code === 0) {
		// 存储一些登陆信息，然后跳转页面
		cookies.set(USERID, r2.data.accountDto?.id);
		cookies.set(USERUUID, r2.data.uniqueId);
		cookies.set(USERNAME, r2.data.nickName);
		// 获取可能存在的回跳url
		return r2
	} else {
		return Promise.reject({ message: r2.msg || '用户信息获取失败',code: -1 })
	}
}

// v-html 标签符合转义
export function escapeHtml(unsafe: string): string {
  if(!unsafe || typeof unsafe != 'string')return ''
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}