import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';
import { SET_INFORMATION, SET_LIST, SET_COMPANY } from './types';
import { momentClient } from '@/utils/axios'
export default class AnalyzeModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {
      list: [],
      lens: 0,
      data: {}
    };
  }

  getters: GetterTree<State, Root> = {
    list: state => state.list,
    lens: state => state.lens,
    data: state => state.data
  }

  actions: ActionTree<State, Root> = {
    // 查询获取解析列表
    async getAnalyzeList({ commit }, params) {
      const r: ReturnPage = await axios.get(`/web/quiz/package/info/result/commitList`, { params });
      if (r.code === 0) {
        commit(SET_COMPANY, r.data);
        commit(SET_INFORMATION, r.data.questionList);
      }
      return r;
    },
    // 查询指定题目的解析结果
    async getAnalyzeByQuestion({}, params) {
      const r: ReturnPage = await axios.get(`/web/quiz/package/info/result/commitInfo`, { params });
      return r;
    },

    //获取错题集列表
    async getErrorRecordList({ commit }, id) {
      const {data} = await momentClient.get(`/moment/api/ke/upackage/error/page/package/${id}`);
      if(data.code == 0){
        data.data = data.data.map((item: any)=> ({...item, optionList: item.options, realQuestion: item.answer}))
        commit(SET_INFORMATION, data.data);
        return Promise.resolve(data)
      }else{
        return Promise.reject(data)
      }
    }
  }

  mutations: MutationTree<State> = {
    [SET_INFORMATION](state, data) {
      state.list = data;
      state.lens = data.length;
    },
    [SET_LIST](state, data) {
      state.list = data;
    },
    [SET_COMPANY](state, data) {
      state.data.packageId = data.packageId;
      state.data.title = data.title;
    }
  }
}
