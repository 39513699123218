import { createStore } from 'vuex';
import '@/utils/axios';
import Index from './index/index';
import Question from './question';
import Result from './result';
import Analyze from './analyze';
import Paper from './paper';
import Feed from './feed';
import User from './user';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Index: new Index(),
    Question: new Question(),
    Result: new Result(),
    Analyze: new Analyze(),
    Paper: new Paper(),
    Feed: new Feed(),
    User: new User()
  }
})
