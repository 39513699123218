// water-market.js
// 水印内容较长可以设置can的width，height属性
const watermark: any = {
  timer: null,
  set: () => {}
}
const setWatermark = (str = '默认水印内容') => {
  const id = 'GLOBAL_WATER_MARK'
  if (document.getElementById(id) !== null) {
    document.body.removeChild((document as any).getElementById(id))
  }
  const can = document.createElement('canvas')
  can.width = 200
  can.height = 200
 
  const cans: any = can.getContext('2d')
  cans.rotate(-20 * Math.PI / 180)
  cans.font = '14px Microsoft YaHei'
  cans.fillStyle = 'rgba(200, 200, 200, 0.2)'
  cans.textAlign = 'left'
  cans.textBaseline = 'middle'
  cans.fillText(str, can.width / 3, can.height / 2)
 
  const WATER_MARK = document.createElement('div')
  const ALL_WIDTH = document.documentElement.clientWidth + 'px'
  const ALL_HEIGHT = document.documentElement.clientHeight + 'px'
 
  WATER_MARK.id = id
  WATER_MARK.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    pointer-events: none;
    width: ${ALL_WIDTH};
    height: ${ALL_HEIGHT};
    background: url(${can.toDataURL('image/png')}) left top repeat
  `
  document.body.appendChild(WATER_MARK)
  return id
}
 
// 该方法只允许调用一次
watermark.set = (str: string) => {
  let id = setWatermark(str)
  watermark.timer && clearTimeout(watermark.timer)
  watermark.timer = setTimeout(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str)
    }
  }, 300)
  window.onresize = () => {
    setWatermark(str)
  }
}
 
const onWholeWaterMark = (str: string): void => watermark.set(str)
export default onWholeWaterMark