import { provide, nextTick } from 'vue';
import { Vue } from "vue-class-component";
import { getAppToken, getPlatform, dispatchAppFeature } from '@/utils/util';
import { LOGINTOKEN } from '@/constants/common';
import cookies from 'js-cookie';
export default class AppVue extends Vue {
  constructor() {
    super(...arguments);
    // 是否显示当前路由模块
    this.showPage = true;
  }
  created() {
    provide('reload', this.onRefresh);
    // 处理app中传递过来的token
    const token = getAppToken();
    if (token) {
      cookies.set(LOGINTOKEN, token);
    }
    // 设置标题
    if (getPlatform().isApp) {
      dispatchAppFeature('initWebViewUIConfigParams', {
        show: true,
        title: '技能测试'
      });
    }
  }
  async onRefresh() {
    this.showPage = false;
    await nextTick();
    this.showPage = true;
    // 滚动到页面顶部
    window.scrollTo(0, 0);
  }
}