import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { ReturnPage } from '@/typings/common';
import axios from 'axios';
import Root from '../state';
import State from './state';
import { SET_DATA, SET_LIST } from './types';
import { logoTextConvert } from '@/utils/util';

export default class PaperModule implements Module<State, Root> {

  namespaced = true;

  state: State;

  constructor() {
    this.state = {
      data: {
        level: 0
      },
      list: []
    };
  }

  getters: GetterTree<State, Root> = {
    data: state => state.data,
    list: state => state.list
  }

  actions: ActionTree<State, Root> = {
    // 获取指定试卷详情
    async getPaperDetail({ commit }, id) {
      
      const r: ReturnPage = await axios.get(`/web/quiz/package/info/${id}`);
      if (r.code === 0) {
        const curr = r.data;
        curr.quizPositionName = (curr.quizPositionName || '').replace(/,/g, ' | ');
        curr.quizCompanyName = (curr.quizCompanyName || '').split(',').map((n: any) => logoTextConvert(n));
        curr.quizCompanyBgColor = (curr.quizCompanyBgColor || '').split(',');
        curr.price = curr.price || 0;
        commit(SET_DATA, curr);
        return r;
      }else{
        return Promise.reject(r)
      }
    },
    // 获取推荐试卷
    async getRecommandPaper({ commit, state }, params) {
      const r: ReturnPage = await axios.post(`/web/quiz/package/recommand`, params);
      if (r.code === 0) {
        const curr = (r.data.data || []).map((n: any) => {
          // 左边图片上的文本显示处理
          n.subName = logoTextConvert(n.subName);
          n.quizPositionName = (n.quizPositionName || '').split(',').join(' | ');
          return n;
        });
        const list = params.pageIndex === 1 ? curr : state.list.concat(curr);
        commit(SET_LIST, list);
      }
      return r;
    }
  }

  mutations: MutationTree<State> = {
    [SET_DATA](state, data) {
      state.data = data;
    },
    [SET_LIST](state, data) {
      state.list = data;
    }
  }
}
