import axios from 'axios';
import cookies from 'js-cookie';
import { LOGINTOKEN } from '@/constants/common';

axios.defaults.baseURL = process.env.VUE_APP_API_PREFIX || '';
// 请求拦截器
axios.interceptors.request.use((config: any) => {
  // 获取token
  const token = cookies.get(LOGINTOKEN) || '';
  if (token) {
    config.headers.token = token;
  }
  return config;
}, (error: any) => {
  console.error(`请求发送出错：${error}`);
});

// 响应拦截器
axios.interceptors.response.use(
  (res: any) => {
    // 获取请求返回值
    return Promise.resolve(res.data);
  },
  (err: any) => {
    console.log(`请求出错：${err} \n${err.response}`);
  }
);

export const momentClient = axios.create({
  baseURL: process.env.VUE_APP_EDU_BASE_URL,
  timeout: 3000,
});

momentClient.interceptors.request.use((config: any) => {
  // 获取token
  const token = cookies.get(LOGINTOKEN) || '';
  if (token) {
    config.headers.token = token;
  }
  return config;
}, (error: any) => {
  console.error(`请求发送出错：${error}`);
});

export default axios;
